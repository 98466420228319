import logo from "./logo.svg";
import "./App.css";

function Home1() {
  return (
    <div>
      <div className="mil-wrapper" id="top">
        <div className="mil-ball">
          <span className="mil-icon-1">
            <svg viewBox="0 0 128 128">
              <path d="M106.1,41.9c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2L116.8,61H11.2l14.9-14.9c1.2-1.2,1.2-3.1,0-4.2	c-1.2-1.2-3.1-1.2-4.2,0l-20,20c-1.2,1.2-1.2,3.1,0,4.2l20,20c0.6,0.6,1.4,0.9,2.1,0.9s1.5-0.3,2.1-0.9c1.2-1.2,1.2-3.1,0-4.2	L11.2,67h105.5l-14.9,14.9c-1.2,1.2-1.2,3.1,0,4.2c0.6,0.6,1.4,0.9,2.1,0.9s1.5-0.3,2.1-0.9l20-20c1.2-1.2,1.2-3.1,0-4.2L106.1,41.9	z" />
            </svg>
          </span>
          <div className="mil-more-text">More</div>
          <div className="mil-choose-text">Сhoose</div>
        </div>

        <div className="mil-preloader">
          <div className="mil-preloader-animation">
            <div className="mil-pos-abs mil-animation-1">
              <p className="mil-h3 mil-muted mil-thin">Pioneering</p>
              <p className="mil-h3 mil-muted">Creative</p>
              <p className="mil-h3 mil-muted mil-thin">Excellence</p>
            </div>
            <div className="mil-pos-abs mil-animation-2">
              <div className="mil-reveal-frame">
                <p className="mil-reveal-box"></p>
                <p className="mil-h3 mil-muted mil-thin">
                  Sahara Post Productions
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mil-progress-track">
          <div className="mil-progress"></div>
        </div>

        <div className="mil-menu-frame">
          <div className="mil-frame-top">
            <a href="/" className="mil-logo">
              A.
            </a>
            <div className="mil-menu-btn">
              <span></span>
            </div>
          </div>

          <div className="container">
            <div className="mil-menu-content">
              <div className="row">
                <div className="col-xl-5">
                  <nav className="mil-main-menu" id="swupMenu">
                    <ul>
                      <li className="mil-has-children mil-active">
                        <a href="/">Homepage</a>
                        <ul>
                          <li>
                            <a href="/">Landing page</a>
                          </li>
                          <li>
                            <a href="/home2">Personal</a>
                          </li>
                          <li>
                            <a href="/portfolio3">Portfolio slider</a>
                          </li>
                        </ul>
                      </li>
                      <li className="mil-has-children">
                        <a href="#portfolio">Portfolio</a>
                        <ul>
                          <li>
                            <a href="/portfolio1">Grid type 1</a>
                          </li>
                          <li>
                            <a href="/portfolio2">Grid type 2</a>
                          </li>
                          <li>
                            <a href="/portfolio3">Slider</a>
                          </li>
                        </ul>
                      </li>
                      <li className="mil-has-children">
                        <a href="#services">Services</a>
                        <ul>
                          <li>
                            <a href="/services">Services List</a>
                          </li>
                          <li>
                            <a href="/service">Single service</a>
                          </li>
                        </ul>
                      </li>
                      <li className="mil-has-children">
                        <a href="/#services">Newsletter</a>
                        <ul>
                          <li>
                            <a href="/blog">Blog List</a>
                          </li>
                          <li>
                            <a href="/publication">Publication</a>
                          </li>
                        </ul>
                      </li>
                      <li className="mil-has-children">
                        <a href="#.">Other pages</a>
                        <ul>
                          <li>
                            <a href="/team">Team</a>
                          </li>
                          <li>
                            <a href="/contact">Contact</a>
                          </li>
                          <li>
                            <a href="/*">404</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="col-xl-7">
                  <div className="mil-menu-right-frame">
                    <div className="mil-animation-in">
                      <div className="mil-animation-frame">
                        <div
                          className="mil-animation mil-position-1 mil-scale"
                          data-value-1="2"
                          data-value-2="2"
                        ></div>
                      </div>
                    </div>
                    <div className="mil-menu-right">
                      <div className="row">
                        <div className="col-lg-8 mil-mb-60">
                          <h6 className="mil-muted mil-mb-30">Projects</h6>

                          <ul className="mil-menu-list">
                            <li>
                              <a href="/project1" className="mil-light-soft">
                                Interior design studio
                              </a>
                            </li>
                            <li>
                              <a href="/project2" className="mil-light-soft">
                                Home Security Camera
                              </a>
                            </li>
                            <li>
                              <a href="/project3" className="mil-light-soft">
                                Kemia Honest Skincare
                              </a>
                            </li>
                            <li>
                              <a href="/project4" className="mil-light-soft">
                                Cascade of Lava
                              </a>
                            </li>
                            <li>
                              <a href="/project5" className="mil-light-soft">
                                Air Pro by Molekule
                              </a>
                            </li>
                            <li>
                              <a href="/project6" className="mil-light-soft">
                                Tony's Chocolonely
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-4 mil-mb-60">
                          <h6 className="mil-muted mil-mb-30">Useful links</h6>

                          <ul className="mil-menu-list">
                            <li>
                              <a href="#." className="mil-light-soft">
                                Privacy Policy
                              </a>
                            </li>
                            <li>
                              <a href="#." className="mil-light-soft">
                                Terms and conditions
                              </a>
                            </li>
                            <li>
                              <a href="#." className="mil-light-soft">
                                Cookie Policy
                              </a>
                            </li>
                            <li>
                              <a href="#." className="mil-light-soft">
                                Careers
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="mil-divider mil-mb-60"></div>
                      <div className="row justify-content-between">
                        <div className="col-lg-4 mil-mb-60">
                          <h6 className="mil-muted mil-mb-30">Canada</h6>

                          <p className="mil-light-soft mil-up">
                            71 South Los Carneros Road, California{" "}
                            <span className="mil-no-wrap">+51 174 705 812</span>
                          </p>
                        </div>
                        <div className="col-lg-4 mil-mb-60">
                          <h6 className="mil-muted mil-mb-30">Germany</h6>

                          <p className="mil-light-soft">
                            Leehove 40, 2678 MC De Lier, Netherlands{" "}
                            <span className="mil-no-wrap">+31 174 705 811</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mil-curtain"></div>

        <div className="mil-frame">
          <div className="mil-frame-top">
            <a href="/" className="mil-logo">
              A.
            </a>
            <div className="mil-menu-btn">
              <span></span>
            </div>
          </div>
          <div className="mil-frame-bottom">
            <div className="mil-current-page "></div>
            <div className="mil-back-to-top">
              <a
                href="#top"
                className="mil-link mil-dark text-white mil-arrow-place"
              >
                <span className="">Back to top</span>
              </a>
            </div>
          </div>
        </div>

        <div className="mil-content">
          <div id="swupMain" className="mil-main-transition">
            <section className="mil-banner mil-dark-bg">
              <div className="mi-invert-fix">
                <div className="mil-animation-frame">
                  <div
                    className="mil-animation mil-position-1 mil-scale"
                    data-value-1="7"
                    data-value-2="1.6"
                  ></div>
                  <div
                    className="mil-animation mil-position-2 mil-scale"
                    data-value-1="4"
                    data-value-2="1"
                  ></div>
                  <div
                    className="mil-animation mil-position-3 mil-scale"
                    data-value-1="1.2"
                    data-value-2=".1"
                  ></div>
                </div>

                <div className="mil-gradient"></div>

                <div className="container">
                  <div className="mil-banner-content mil-up">
                    <h1 className="mil-muted mil-mb-60">
                      <span className="mil-thin">Turn Your</span> Videos <br />{" "}
                      <span className="mil-thin">into </span>
                      <span className="mil-thin">Digital</span> Gold
                      {/* <span className="mil-thin"> Best </span>Video editors
                      <br />
                      <span className="mil-thin"> in Austin,TX</span> */}
                    </h1>
                    <div className="row">
                      <div className="col-md-7 col-lg-5">
                        <p className="mil-light-soft mil-mb-60">
                          We provide the best video editing for Youtube, Tiktok
                          and Instagram reels for your business. At Sahara
                          Post-production, we turn your footage into cinematic
                          wonders for companies and startups based in USA,
                          Canada and Australia.
                        </p>
                      </div>
                    </div>

                    <a
                      href="/services"
                      className="mil-button mil-arrow-place mil-btn-space"
                    >
                      <span>What we do</span>
                    </a>

                    <a
                      href="/portfolio1"
                      className="mil-link mil-muted mil-arrow-place"
                    >
                      <span>View works</span>
                    </a>

                    <div className="mil-circle-text">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        // xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 300 300"
                        enable-background="new 0 0 300 300"
                        // xml:space="preserve"
                        className="mil-ct-svg mil-rotate"
                        data-value="360"
                      >
                        <defs>
                          <path
                            id="circlePath"
                            d="M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
                          />
                        </defs>
                        <circle cx="150" cy="100" r="75" fill="none" />
                        <g>
                          {/* <use xlink:href="#circlePath" fill="none" /> */}
                          <text style={{ letterSpacing: "6.5px" }}>
                            {/* <textPath xlink:href="#circlePath">
                              Scroll down - Scroll down -{" "}
                            </textPath> */}
                          </text>
                        </g>
                      </svg>
                      <a
                        href="#about"
                        className="mil-button mil-arrow-place mil-icon-button mil-arrow-down"
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="about">
              <div className="container  mil-p-120-30">
                <div className="row justify-content-between align-items-center">
                  <div className="col-lg-6 col-xl-5">
                    <div className="mil-mb-90">
                      <h2 className="mil-up mil-mb-60">
                        Discover <br />
                        Our <span className="mil-thin">Studio</span>
                      </h2>
                      <p className="mil-up mil-mb-30">
                        At our editing studio, we are a collective of talented
                        individuals ignited by our unwavering passion for
                        transforming ideas into reality. With a harmonious blend
                        of diverse backgrounds and a vast array of skill sets,
                        we create compelling content for our esteemed clients,
                        specializing in YouTube Shorts video editing and
                        crafting engaging TikTok and IG Reels content.
                      </p>

                      <p className="mil-up mil-mb-60">
                        Collaboration is at the heart of our editing Studio. Our
                        team thrives on the synergy that arises when unique
                        perspectives converge, fostering an environment of
                        boundless creativity. Serving clients from the USA,
                        Canada, and Australia, we harness our collective
                        expertise to produce extraordinary results that
                        consistently surpass expectations.
                      </p>

                      <div className="mil-about-quote">
                        <div className="mil-avatar mil-up">
                          <img src="img/faces/customers/2.jpg" alt="Founder" />
                        </div>
                        <h6 className="mil-quote mil-up">
                          Passionately Creating{" "}
                          <span className="mil-thin">Video Wonders:</span>{" "}
                          Unleashing{" "}
                          <span className="mil-thin">Boundless Creativity</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="mil-about-photo mil-mb-90">
                      <div className="mil-lines-place"></div>
                      <div
                        className="mil-up mil-img-frame"
                        style={{ paddingBottom: "160%" }}
                      >
                        <img
                          src="img/photo/1.jpg"
                          alt="img"
                          className="mil-scale"
                          data-value-1="1"
                          data-value-2="1.2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="mil-dark-bg">
              <div className="mi-invert-fix">
                <div className="mil-animation-frame">
                  <div
                    className="mil-animation mil-position-1 mil-scale"
                    data-value-1="2.4"
                    data-value-2="1.4"
                    style={{ top: "300px", right: "-100px" }}
                  ></div>
                  <div
                    className="mil-animation mil-position-2 mil-scale"
                    data-value-1="2"
                    data-value-2="1"
                    style={{ left: "150px" }}
                  ></div>
                </div>
                <div className="container mil-p-120-0">
                  <div className="mil-mb-120">
                    <div className="row">
                      <div className="col-lg-10">
                        <span className="mil-suptitle mil-light-soft mil-suptitle-right mil-up">
                          Professionals focused on helping your brand
                          <br /> grow and move forward.
                        </span>
                      </div>
                    </div>

                    <div className="mil-complex-text justify-content-center mil-up mil-mb-15">
                      <span className="mil-text-image">
                        <img src="img/photo/2.jpg" alt="team" />
                      </span>
                      <h2 className="mil-h1 mil-muted mil-center">
                        Unique <span className="mil-thin">Ideas</span>
                      </h2>
                    </div>
                    <div className="mil-complex-text justify-content-center mil-up">
                      <h2 className="mil-h1 mil-muted mil-center">
                        For Your <span className="mil-thin">Business.</span>
                      </h2>
                      <a
                        href="/services"
                        className="mil-services-button mil-button mil-arrow-place"
                      >
                        <span>What we do</span>
                      </a>
                    </div>
                  </div>

                  <div className="row mil-services-grid m-0">
                    <div className="col-md-6 col-lg-3 mil-services-grid-item p-0">
                      <a href="/service" className="mil-service-card-sm mil-up">
                        <h5 className="mil-muted mil-mb-30">
                          Creative Consulting <br />
                          and Development
                        </h5>
                        <p className="mil-light-soft mil-mb-30">
                          Our creative agency is a team of professionals focused
                          on helping your brand grow.
                        </p>
                        <div className="mil-button mil-icon-button-sm mil-arrow-place"></div>
                      </a>
                    </div>
                    <div className="col-md-6 col-lg-3 mil-services-grid-item p-0">
                      <a href="/service" className="mil-service-card-sm mil-up">
                        <h5 className="mil-muted mil-mb-30">
                          Video Editing <br /> Services
                        </h5>
                        <p className="mil-light-soft mil-mb-30">
                          Forget boring cuts and dull transitions. At Sahara
                          Post-production, we turn your footage into cinematic
                          wonders for company and startup based in Austin Texas.
                        </p>
                        <div className="mil-button mil-icon-button-sm mil-arrow-place"></div>
                      </a>
                    </div>
                    <div className="col-md-6 col-lg-3 mil-services-grid-item p-0">
                      <a href="/service" className="mil-service-card-sm mil-up">
                        <h5 className="mil-muted mil-mb-30">
                          Video Production <br />
                          (Kenya Only)
                        </h5>
                        <p className="mil-light-soft mil-mb-30">
                          In Kenya, we elevate video production for NGOs and
                          companies
                        </p>
                        <div className="mil-button mil-icon-button-sm mil-arrow-place"></div>
                      </a>
                    </div>
                    <div className="col-md-6 col-lg-3 mil-services-grid-item p-0">
                      <a href="/service" className="mil-service-card-sm mil-up">
                        <h5 className="mil-muted mil-mb-30">
                          AI and Deepfake <br /> Video Creation
                        </h5>
                        <p className="mil-light-soft mil-mb-30">
                          Making tech do the hard work while we take the credit.
                        </p>
                        <div className="mil-button mil-icon-button-sm mil-arrow-place"></div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="team">
              <div className="container mil-p-120-30">
                <div className="row justify-content-between align-items-center">
                  <div className="col-lg-5 col-xl-4">
                    <div className="mil-mb-90">
                      <h2 className="mil-up mil-mb-60">
                        Meet <br />
                        Our Team
                      </h2>
                      <p className="mil-up mil-mb-30">
                        We are talented individuals who are passionate about
                        video editing and video production. With a diverse range
                        of backgrounds and skill sets including Adobe Premiere
                        Pro, After Effects, Final Cut Pro, Davinci Resolve and
                        Capcut we collaborate to produce exceptional Videos for
                        our clients.
                      </p>

                      <p className="mil-up mil-mb-60">
                        Together, our creative team is committed to delivering
                        impactful work that exceeds expectations.
                      </p>

                      <div className="mil-up">
                        <a
                          href="/team"
                          className="mil-button mil-arrow-place mil-mb-60"
                        >
                          <span>Read more</span>
                        </a>
                      </div>

                      <h4 className="mil-up">
                        <span className="mil-thin">We</span> delivering <br />
                        <span className="mil-thin">exceptional</span> Videos.
                      </h4>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mil-team-list">
                      <div className="mil-lines-place"></div>

                      <div className="row mil-mb-60">
                        <div className="col-sm-6">
                          <div className="mil-team-card mil-up mil-mb-30">
                            <img src="img/faces/1.jpeg" alt="Team member" />
                            <div className="mil-description">
                              <div className="mil-secrc-text">
                                <h5 className="mil-muted mil-mb-5">
                                  <a href="/home2">Joseph Ndung'u</a>
                                </h5>
                                <p className="mil-link mil-light-soft mil-mb-10">
                                  CEO And Founder
                                </p>
                                <ul className="mil-social-icons mil-center">
                                  <li>
                                    <a
                                      href="#."
                                      target="_blank"
                                      className="social-icon"
                                    >
                                      {" "}
                                      <i className="fab fa-behance"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#."
                                      target="_blank"
                                      className="social-icon"
                                    >
                                      {" "}
                                      <i className="fab fa-dribbble"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#."
                                      target="_blank"
                                      className="social-icon"
                                    >
                                      {" "}
                                      <i className="fab fa-twitter"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#."
                                      target="_blank"
                                      className="social-icon"
                                    >
                                      {" "}
                                      <i className="fab fa-github"></i>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="mil-team-card mil-up mil-mb-30">
                            <img src="img/faces/3.jpg" alt="Team member" />
                            <div className="mil-description">
                              <div className="mil-secrc-text">
                                <h5 className="mil-muted mil-mb-5">
                                  <a href="/home2">Andrew Gagi</a>
                                </h5>
                                <p className="mil-link mil-light-soft mil-mb-10">
                                  Senior Developer
                                </p>
                                <ul className="mil-social-icons mil-center">
                                  <li>
                                    <a
                                      href="#."
                                      target="_blank"
                                      className="social-icon"
                                    >
                                      {" "}
                                      <i className="fab fa-behance"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#."
                                      target="_blank"
                                      className="social-icon"
                                    >
                                      {" "}
                                      <i className="fab fa-dribbble"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#."
                                      target="_blank"
                                      className="social-icon"
                                    >
                                      {" "}
                                      <i className="fab fa-twitter"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#."
                                      target="_blank"
                                      className="social-icon"
                                    >
                                      {" "}
                                      <i className="fab fa-github"></i>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <p
                            className="mil-mobile-hidden mil-text-sm mil-mb-30"
                            style={{ height: "30px" }}
                          >
                            <span className="mil-accent">*</span> The founders
                            of our agency
                          </p>

                          <div className="mil-team-card mil-up mil-mb-30">
                            <img src="img/faces/2.jpg" alt="Team member" />
                            <div className="mil-description">
                              <div className="mil-secrc-text">
                                <h5 className="mil-muted mil-mb-5">
                                  <a href="/home2">Emma Newman</a>
                                </h5>
                                <p className="mil-link mil-light-soft mil-mb-10">
                                  Founder
                                </p>
                                <ul className="mil-social-icons mil-center">
                                  <li>
                                    <a
                                      href="#."
                                      target="_blank"
                                      className="social-icon"
                                    >
                                      {" "}
                                      <i className="fab fa-behance"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#."
                                      target="_blank"
                                      className="social-icon"
                                    >
                                      {" "}
                                      <i className="fab fa-dribbble"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#."
                                      target="_blank"
                                      className="social-icon"
                                    >
                                      {" "}
                                      <i className="fab fa-twitter"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#."
                                      target="_blank"
                                      className="social-icon"
                                    >
                                      {" "}
                                      <i className="fab fa-github"></i>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="mil-team-card mil-up mil-mb-30">
                            <img src="img/faces/4.jpg" alt="Team member" />
                            <div className="mil-description">
                              <div className="mil-secrc-text">
                                <h5 className="mil-muted mil-mb-5">
                                  <a href="/home2">Lisa Trueman</a>
                                </h5>
                                <p className="mil-link mil-light-soft mil-mb-10">
                                  UI/UX Designer
                                </p>
                                <ul className="mil-social-icons mil-center">
                                  <li>
                                    <a
                                      href="#."
                                      target="_blank"
                                      className="social-icon"
                                    >
                                      {" "}
                                      <i className="fab fa-behance"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#."
                                      target="_blank"
                                      className="social-icon"
                                    >
                                      {" "}
                                      <i className="fab fa-dribbble"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#."
                                      target="_blank"
                                      className="social-icon"
                                    >
                                      {" "}
                                      <i className="fab fa-twitter"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#."
                                      target="_blank"
                                      className="social-icon"
                                    >
                                      {" "}
                                      <i className="fab fa-github"></i>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="mil-white ">
              <div className="container mil-p-120-120">
                <div className="row">
                  <div className="col-lg-10">
                    <span className="mil-suptitle mil-suptitle-right mil-suptitle-dark mil-up">
                      Customer reviews are a valuable source <br />
                      of information for both businesses and consumers.
                    </span>
                  </div>
                </div>

                <h2 className="mil-center mil-up mil-mb-60">
                  Customer <span className="mil-thin">Voices:</span> <br />
                  Hear What <span className="mil-thin">They Say!</span>
                </h2>

                <div className="mil-revi-pagination mil-up mil-mb-60"></div>

                <div className="row mil-relative justify-content-center">
                  <div className="col-lg-8">
                    <div className="mil-slider-nav mil-soft mil-reviews-nav mil-up">
                      <div className="mil-slider-arrow mil-prev mil-revi-prev mil-arrow-place"></div>
                      <div className="mil-slider-arrow mil-revi-next mil-arrow-place"></div>
                    </div>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                      className="mil-quote-icon mil-up"
                    >
                      <path
                        d="M 13.5 10 A 8.5 8.5 0 0 0 13.5 27 A 8.5 8.5 0 0 0 18.291016 25.519531 C 17.422273 29.222843 15.877848 31.803343 14.357422 33.589844 C 12.068414 36.279429 9.9433594 37.107422 9.9433594 37.107422 A 1.50015 1.50015 0 1 0 11.056641 39.892578 C 11.056641 39.892578 13.931586 38.720571 16.642578 35.535156 C 19.35357 32.349741 22 27.072581 22 19 A 1.50015 1.50015 0 0 0 21.984375 18.78125 A 8.5 8.5 0 0 0 13.5 10 z M 34.5 10 A 8.5 8.5 0 0 0 34.5 27 A 8.5 8.5 0 0 0 39.291016 25.519531 C 38.422273 29.222843 36.877848 31.803343 35.357422 33.589844 C 33.068414 36.279429 30.943359 37.107422 30.943359 37.107422 A 1.50015 1.50015 0 1 0 32.056641 39.892578 C 32.056641 39.892578 34.931586 38.720571 37.642578 35.535156 C 40.35357 32.349741 43 27.072581 43 19 A 1.50015 1.50015 0 0 0 42.984375 18.78125 A 8.5 8.5 0 0 0 34.5 10 z"
                        fill="#000000"
                      />
                    </svg>

                    <div className="swiper-container mil-reviews-slider">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide">
                          <div
                            className="mil-review-frame mil-center"
                            data-swiper-parallax="-200"
                            data-swiper-parallax-opacity="0"
                          >
                            <h5 className="mil-up mil-mb-10">Sarah Newman</h5>
                            <p className="mil-mb-5 mil-upper mil-up mil-mb-30">
                              Borenstein Group
                            </p>
                            <p className="mil-text-xl mil-up">
                              "worked on video for a website opening. They were
                              very responsive delivered on time and friendly. I
                              would definitely work with them again!"
                            </p>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div
                            className="mil-review-frame mil-center"
                            data-swiper-parallax="-200"
                            data-swiper-parallax-opacity="0"
                          >
                            <h5 className="mil-up mil-mb-10">Drew Brania</h5>
                            <p className="mil-mb-5 mil-upper mil-up mil-mb-30">
                              Inspiring Speakers
                            </p>
                            <p className="mil-text-xl mil-up">
                              They are grat contractors to work with honest,
                              hard working charges reasonable rates and very
                              skilled in what they do. They worked on several
                              videos for me and always did an excellent and very
                              high quality proffesional job.
                            </p>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div
                            className="mil-review-frame mil-center"
                            data-swiper-parallax="-200"
                            data-swiper-parallax-opacity="0"
                          >
                            <h5 className="mil-up mil-mb-10">RJ Finnegan</h5>
                            <p className="mil-mb-5 mil-upper mil-up mil-mb-30">
                              SPI Logistics
                            </p>
                            <p className="mil-text-xl mil-up">
                              "Sahara Post-production turned our footage into a
                              blockbuster YouTube shorts. We now have a vibrant
                              online presence."
                            </p>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div
                            className="mil-review-frame mil-center"
                            data-swiper-parallax="-200"
                            data-swiper-parallax-opacity="0"
                          >
                            <h5 className="mil-up mil-mb-10">Tyler Mills</h5>
                            <p className="mil-mb-5 mil-upper mil-up mil-mb-30">
                              Travel Vlog
                            </p>
                            <p className="mil-text-xl mil-up">
                              Sahara Postproduction did a great job, and
                              communicated well."
                            </p>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div
                            className="mil-review-frame mil-center"
                            data-swiper-parallax="-200"
                            data-swiper-parallax-opacity="0"
                          >
                            <h5 className="mil-up mil-mb-10">Andrea Lee</h5>
                            <p className="mil-mb-5 mil-upper mil-up mil-mb-30">
                              Perth Branding Australia
                            </p>
                            <p className="mil-text-xl mil-up">
                              "Sahara Post-production is the secret sauce to our
                              successful Impact video”
                            </p>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div
                            className="mil-review-frame mil-center"
                            data-swiper-parallax="-200"
                            data-swiper-parallax-opacity="0"
                          >
                            <h5 className="mil-up mil-mb-10">Emma Trueman</h5>
                            <p className="mil-mb-5 mil-upper mil-up mil-mb-30">
                              Envato market
                            </p>
                            <p className="mil-text-xl mil-up">
                              I had the pleasure of working with this creative
                              agency, and I must say, they truly impressed me.
                              They consistently think outside the box, resulting
                              in impressive and impactful work. I highly
                              recommend this agency for their consistent
                              delivery of exceptional creative solutions.
                            </p>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div
                            className="mil-review-frame mil-center"
                            data-swiper-parallax="-200"
                            data-swiper-parallax-opacity="0"
                          >
                            <h5 className="mil-up mil-mb-10">Sarah Newman</h5>
                            <p className="mil-mb-5 mil-upper mil-up mil-mb-30">
                              Envato market
                            </p>
                            <p className="mil-text-xl mil-up">
                              This creative agency stands out with their
                              exceptional talent and expertise. Their ability to
                              think outside the box and bring unique ideas to
                              life is truly impressive. With meticulous
                              attention to detail, they consistently deliver
                              visually stunning and impactful work.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="mil-white ">
              <div className="container mil-p-0-120">
                <div className="swiper-container mil-infinite-show mil-up">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <a
                        href="#."
                        className="mil-partner-frame"
                        style={{ width: "60px" }}
                      >
                        <img src="img/partners/1.svg" alt="logo" />
                      </a>
                    </div>
                    <div className="swiper-slide">
                      <a
                        href="#."
                        className="mil-partner-frame"
                        style={{ width: "100px" }}
                      >
                        <img src="img/partners/2.svg" alt="logo" />
                      </a>
                    </div>
                    <div className="swiper-slide">
                      <a
                        href="#."
                        className="mil-partner-frame"
                        style={{ width: "60px" }}
                      >
                        <img src="img/partners/1.svg" alt="logo" />
                      </a>
                    </div>
                    <div className="swiper-slide">
                      <a
                        href="#."
                        className="mil-partner-frame"
                        style={{ width: "100px" }}
                      >
                        <img src="img/partners/2.svg" alt="logo" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <section>
              <div className="container mil-p-120-60">
                <div className="row align-items-center mil-mb-30">
                  <div className="col-lg-6 mil-mb-30">
                    <h3 className="mil-up">Popular Publications:</h3>
                  </div>
                  <div className="col-lg-6 mil-mb-30">
                    <div className="mil-adaptive-right mil-up">
                      <a
                        href="/blog"
                        className="mil-link mil-dark mil-arrow-place"
                      >
                        <span>View all</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <a href="/publication" className="mil-blog-card mil-mb-60">
                      <div className="mil-cover-frame mil-up">
                        <img src="img/blog/1.jpg" alt="cover" />
                      </div>
                      <div className="mil-post-descr">
                        <div className="mil-labels mil-up mil-mb-30">
                          <div className="mil-label mil-upper mil-accent">
                            TECHNOLOGY
                          </div>
                          <div className="mil-label mil-upper">may 24 2023</div>
                        </div>
                        <h4 className="mil-up mil-mb-30">
                          How to Become a Graphic Designer in 10 Simple Steps
                        </h4>
                        <p className="mil-post-text mil-up mil-mb-30">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Eius sequi commodi dignissimos optio, beatae,
                          eos necessitatibus nisi. Nam cupiditate consectetur
                          nostrum qui! Repellat natus nulla, nisi aliquid,
                          asperiores impedit tempora sequi est reprehenderit
                          cumque explicabo, dicta. Rem nihil ullam totam ea
                          voluptas quibusdam repudiandae id ut at iure! Totam,
                          a!
                        </p>
                        <div className="mil-link mil-dark mil-arrow-place mil-up">
                          <span>Read more</span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <a href="/publication" className="mil-blog-card mil-mb-60">
                      <div className="mil-cover-frame mil-up">
                        <img src="img/blog/2.jpg" alt="cover" />
                      </div>
                      <div className="mil-post-descr">
                        <div className="mil-labels mil-up mil-mb-30">
                          <div className="mil-label mil-upper mil-accent">
                            TECHNOLOGY
                          </div>
                          <div className="mil-label mil-upper">may 24 2023</div>
                        </div>
                        <h4 className="mil-up mil-mb-30">
                          16 Best Graphic Design Online and Offline Courses
                        </h4>
                        <p className="mil-post-text mil-up mil-mb-30">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Eius sequi commodi dignissimos optio, beatae,
                          eos necessitatibus nisi. Nam cupiditate consectetur
                          nostrum qui! Repellat natus nulla, nisi aliquid,
                          asperiores impedit tempora sequi est reprehenderit
                          cumque explicabo, dicta. Rem nihil ullam totam ea
                          voluptas quibusdam repudiandae id ut at iure! Totam,
                          a!
                        </p>
                        <div className="mil-link mil-dark mil-arrow-place mil-up">
                          <span>Read more</span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </section>

            <footer className="mil-dark-bg">
              <div className="mi-invert-fix">
                <div className="container mil-p-120-60">
                  <div className="row justify-content-between">
                    <div className="col-md-4 col-lg-4 mil-mb-60">
                      <div className="mil-muted mil-logo mil-up mil-mb-30">
                        Sahara Post Productions
                      </div>

                      <p className="mil-light-soft mil-up mil-mb-30">
                        Subscribe our newsletter:
                      </p>

                      <form className="mil-subscribe-form mil-up">
                        <input type="text" placeholder="Enter our email" />
                        <button
                          type="submit"
                          className="mil-button mil-icon-button-sm mil-arrow-place"
                        ></button>
                      </form>
                    </div>
                    <div className="col-md-7 col-lg-6">
                      <div className="row justify-content-end">
                        <div className="col-md-6 col-lg-7">
                          <nav className="mil-footer-menu mil-mb-60">
                            <ul>
                              <li className="mil-up mil-active">
                                <a href="/">Home</a>
                              </li>
                              <li className="mil-up">
                                <a href="/portfolio1">Portfolio</a>
                              </li>
                              <li className="mil-up">
                                <a href="/services">Services</a>
                              </li>
                              <li className="mil-up">
                                <a href="/contact">Contact</a>
                              </li>
                              <li className="mil-up">
                                <a href="/blog">Blog</a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                        <div className="col-md-6 col-lg-5">
                          <ul className="mil-menu-list mil-up mil-mb-60">
                            <li>
                              <a href="/" className="mil-light-soft">
                                Privacy Policy
                              </a>
                            </li>
                            <li>
                              <a href="/" className="mil-light-soft">
                                Terms and conditions
                              </a>
                            </li>
                            <li>
                              <a href="/" className="mil-light-soft">
                                Cookie Policy
                              </a>
                            </li>
                            <li>
                              <a href="/" className="mil-light-soft">
                                Careers
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-between flex-sm-row-reverse">
                    <div className="col-md-7 col-lg-6">
                      <div className="row justify-content-between">
                        <div className="col-md-6 col-lg-5 mil-mb-60">
                          <h6 className="mil-muted mil-up mil-mb-30">Canada</h6>

                          <p className="mil-light-soft mil-up">
                            71 South Los Carneros Road, California{" "}
                            <span className="mil-no-wrap">+51 174 705 812</span>
                          </p>
                        </div>
                        <div className="col-md-6 col-lg-5 mil-mb-60">
                          <h6 className="mil-muted mil-up mil-mb-30">
                            Germany
                          </h6>

                          <p className="mil-light-soft mil-up">
                            Leehove 40, 2678 MC De Lier, Netherlands{" "}
                            <span className="mil-no-wrap">+31 174 705 811</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-6 mil-mb-60">
                      <div className="mil-vert-between">
                        <div className="mil-mb-30">
                          <ul className="mil-social-icons mil-up">
                            <li>
                              <a
                                href="#."
                                target="_blank"
                                className="social-icon"
                              >
                                {" "}
                                <i className="far fa-circle"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#."
                                target="_blank"
                                className="social-icon"
                              >
                                {" "}
                                <i className="far fa-circle"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#."
                                target="_blank"
                                className="social-icon"
                              >
                                {" "}
                                <i className="far fa-circle"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#."
                                target="_blank"
                                className="social-icon"
                              >
                                {" "}
                                <i className="far fa-circle"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <p className="mil-light-soft mil-up">
                          © Copyright 2024. All Rights Reserved.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>

            <div className="mil-hidden-elements">
              <div className="mil-dodecahedron">
                <div className="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div className="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div className="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div className="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div className="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div className="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div className="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div className="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div className="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div className="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div className="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div className="mil-pentagon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="mil-arrow"
              >
                <path d="M 14 5.3417969 C 13.744125 5.3417969 13.487969 5.4412187 13.292969 5.6367188 L 13.207031 5.7226562 C 12.816031 6.1136563 12.816031 6.7467188 13.207031 7.1367188 L 17.070312 11 L 4 11 C 3.448 11 3 11.448 3 12 C 3 12.552 3.448 13 4 13 L 17.070312 13 L 13.207031 16.863281 C 12.816031 17.254281 12.816031 17.887344 13.207031 18.277344 L 13.292969 18.363281 C 13.683969 18.754281 14.317031 18.754281 14.707031 18.363281 L 20.363281 12.707031 C 20.754281 12.316031 20.754281 11.682969 20.363281 11.292969 L 14.707031 5.6367188 C 14.511531 5.4412187 14.255875 5.3417969 14 5.3417969 z" />
              </svg>

              <svg
                width="250"
                viewBox="0 0 300 1404"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mil-lines"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1 892L1 941H299V892C299 809.71 232.29 743 150 743C67.7096 743 1 809.71 1 892ZM0 942H300V892C300 809.157 232.843 742 150 742C67.1573 742 0 809.157 0 892L0 942Z"
                  className="mil-move"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M299 146V97L1 97V146C1 228.29 67.7096 295 150 295C232.29 295 299 228.29 299 146ZM300 96L0 96V146C0 228.843 67.1573 296 150 296C232.843 296 300 228.843 300 146V96Z"
                  className="mil-move"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M299 1H1V1403H299V1ZM0 0V1404H300V0H0Z"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M150 -4.37115e-08L150 1404L149 1404L149 0L150 -4.37115e-08Z"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M150 1324C232.29 1324 299 1257.29 299 1175C299 1092.71 232.29 1026 150 1026C67.7096 1026 1 1092.71 1 1175C1 1257.29 67.7096 1324 150 1324ZM150 1325C232.843 1325 300 1257.84 300 1175C300 1092.16 232.843 1025 150 1025C67.1573 1025 0 1092.16 0 1175C0 1257.84 67.1573 1325 150 1325Z"
                  className="mil-move"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M300 1175H0V1174H300V1175Z"
                  className="mil-move"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M150 678C232.29 678 299 611.29 299 529C299 446.71 232.29 380 150 380C67.7096 380 1 446.71 1 529C1 611.29 67.7096 678 150 678ZM150 679C232.843 679 300 611.843 300 529C300 446.157 232.843 379 150 379C67.1573 379 0 446.157 0 529C0 611.843 67.1573 679 150 679Z"
                  className="mil-move"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M299 380H1V678H299V380ZM0 379V679H300V379H0Z"
                  className="mil-move"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home1;
